import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    title: {
      type: String
    },
    img: {
      type: []
    },
    color: {
      type: String,
      default: '#ffffff'
    },
    showImg: {
      type: Boolean
    },
    safeMargin: {
      type: Boolean,
      default: true
    },
    imgFullScreen: {
      type: Boolean,
      default: false
    },
    imgHeight: {
      type: Number,
      default: 250
    }
  },
  data() {
    return {
      path: ''
    };
  },
  created() {
    let path = this.$route.fullPath;
    this.path = path.split('?')[0];
  }
});