import { getPageMessage, getAdImgAndVideo } from '@/api';
import ptitle from '../../components/pageTitle/index.vue';
import { useRoute } from 'vue-router';
import { defineComponent } from 'vue';
import { useAppStore } from '@/store';
const route = useRoute();
const store = useAppStore();
export default defineComponent({
  components: {
    ptitle
  },
  data() {
    return {
      store_: store,
      title: '',
      contentSource: [],
      content: [],
      img: '',
      route: useRoute(),
      subName: '',
      pathId: '',
      pathName: '',
      href: '',
      adShow: true,
      adNum: 0,
      windowWidth: 0,
      topDistance: store.getMoveDistance(),
      showCard: false,
      cardClickedIndex: 0,
      moveNum: 0
    };
  },
  async created() {
    // let query = store.getRouter()
    // if (query == '') {//这样做的原因是因为页面的参数都是通过临时缓存传递的，当页面刷新后，临时数据会被清除，所以当用户在模版页面刷新，此时临时数据被刷新掉了的时候，就无法获取当前页的数据，所以跳回首页，让用户自己点击导航栏跳转
    //     this.$router.push({ path: '/home' })
    // } else {
    // store.setTopBarIndex(6)
    this.getMessage(this.reData());
    // }
  },

  watch: {
    '$route': 'getMsg_'
  },
  methods: {
    getMsg_() {
      this.getMessage(this.reData());
    },
    reData() {
      if (store.router.query) {
        return store.router.query;
      }
      let href = JSON.parse(JSON.stringify(window.location.href));
      let hasAD = href.indexOf('adNum');
      let ppid = href.indexOf('&ppid=');
      let query = hasAD == -1 ? {
        pathId: ppid == -1 ? href.substring(href.indexOf('pathId=') + 7, href.indexOf('&listType=')) : href.substring(href.indexOf('pathId=') + 7, ppid),
        ppid: href.indexOf('&ppid=') == -1 ? 0 : href.substring(href.indexOf('&ppid=') + 6)
      } : {
        pathId: href.substring(href.indexOf('pathId=') + 7, href.indexOf('&name=')),
        name: decodeURIComponent(href.substring(href.indexOf('&name=') + 6, href.indexOf('&listType=')))
      };
      let type = /\+/gm;
      let params = JSON.stringify(query).replace(type, ' ');
      return JSON.parse(params);
    },
    async getTitle() {
      let query = store.getRouter().query;
      let adid = query.adNum;
      if (adid != 25) {
        if (adid == '' || adid == undefined) {
          adid = 25;
        }
      }
      this.subName = decodeURIComponent(query.subName) ? decodeURIComponent(query.subName) : decodeURIComponent(query.name);
      this.pathId = query.pathId;
      this.pathName = decodeURIComponent(query.name);
      this.href = query.href;
      try {
        const img = await getAdImgAndVideo(adid);
        this.img = img.data.ad_list[0].pic;
      } catch (e) {
        console.error(e);
      }
    },
    showModel() {
      let query = store.getRouter().query;
      if (query.adShow == 0) {
        this.adShow = false;
      } else {
        this.adShow = true;
      }
    },
    async getMessage(query) {
      this.windowWidth = window.innerWidth;
      query = this.reData();
      // let cc = setInterval(() => {
      //     this.moveNum = store.getMoveDistance()
      // }, 10)
      // if (this.$route.fullPath.indexOf('richText') == -1) {
      //     clearInterval(cc)
      // }
      // let query = route
      // this.getTitle()
      try {
        var _res$data$name;
        const res = await getPageMessage(query.pathId);
        this.title = (_res$data$name = res.data.name) !== null && _res$data$name !== void 0 ? _res$data$name : res.data[0].name;
        if (query.pathId == store.getPageID('aboutUsDOLPHIN') || query.pathId == store.getPageID('aboutUsUSE') || query.pathId == store.getPageID('aboutUsCall') || query.pathId == store.getPageID('populationResearch')) {
          //比较特殊的页内导航
          this.showCard = true;
          this.contentSource = res.data.content_list;
          this.cardItemsClicked(0);
        } else {
          this.showCard = false;
          let index = query.ppid;
          let type = /font-family/gm; //过滤这个条件，把原始富文本的font-family 去掉，以便全局的字体样式生效
          if (index) {
            this.content = res.data.content_list[index].detail.content.replace(type, ''); //有index说明是从模板页面跳转的，这样可以根据模板页中点击的下标过滤符文本
            this.title = res.data.content_list[index].title;
          } else {
            this.content = res.data.content_list[0].detail.content.replace(type, ''); //没有index 就直接去content_list 的第一个
            this.title = res.data.content_list[0].title;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    toHref() {
      this.$router.push({
        path: this.href
      });
      let data = {
        query: {
          pathId: this.pathId,
          name: this.subName
        }
      };
      store.setRouter(data);
    },
    cardItemsClicked(index) {
      this.cardClickedIndex = index;
      let type = /font-family/gm; //过滤这个条件，把原始富文本的font-family 去掉，以便全局的字体样式生效
      this.content = this.contentSource[index].detail.content.replace(type, '');
    }
  }
});