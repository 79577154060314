import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "richText XhorizontalCenter"
};
const _hoisted_2 = {
  key: 1,
  style: {
    "height": "88px"
  }
};
const _hoisted_3 = {
  class: "localPath Allmiddle"
};
const _hoisted_4 = {
  key: 0,
  class: "flowCard"
};
const _hoisted_5 = {
  class: "card-header"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "scrollBars"
};
const _hoisted_8 = {
  class: "backgroundcolor"
};
const _hoisted_9 = ["innerHTML"];
const _hoisted_10 = {
  key: 3,
  class: "container"
};
const _hoisted_11 = {
  class: "row"
};
const _hoisted_12 = {
  class: "col-12 col-lg-3"
};
const _hoisted_13 = {
  class: "card-header"
};
const _hoisted_14 = ["onClick"];
const _hoisted_15 = {
  class: "col-12 col-lg-8 mt-2 pb-5"
};
const _hoisted_16 = {
  class: "richtext"
};
const _hoisted_17 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ptitle = _resolveComponent("ptitle");
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_empty = _resolveComponent("el-empty");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.adShow ? (_openBlock(), _createBlock(_component_ptitle, {
    key: 0,
    title: decodeURIComponent(_ctx.title),
    img: _ctx.img
  }, null, 8, ["title", "img"])) : (_openBlock(), _createElementBlock("div", _hoisted_2)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_breadcrumb, {
    "separator-class": "el-icon-arrow-right"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
      to: {
        path: '/'
      }
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.store_.systemMode('home')), 1)]),
      _: 1
    }), _createVNode(_component_el_breadcrumb_item, null, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(decodeURIComponent(_ctx.title)), 1)]),
      _: 1
    })]),
    _: 1
  })]), false ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass(["flexX subBox", _ctx.windowWidth < 1601 ? 'contentNarrow' : ''])
  }, [_ctx.showCard ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_card, {
    class: "box-card"
  }, {
    header: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(decodeURIComponent(_ctx.title)), 1), _createVNode(_component_el_button, {
      class: "button",
      text: ""
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.cardClickedIndex + 1), 1)]),
      _: 1
    })])]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentSource, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["text item", _ctx.cardClickedIndex == index ? 'cardItemsClicked' : '']),
        onClick: $event => _ctx.cardItemsClicked(index)
      }, _toDisplayString(item.title), 11, _hoisted_6);
    }), 128))]),
    _: 1
  })])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [!_ctx.content ? (_openBlock(), _createBlock(_component_el_empty, {
    key: 0,
    description: "description",
    default: "内容为空"
  })) : (_openBlock(), _createElementBlock("div", {
    style: {
      "font-family": "'ThinkOfSource'"
    },
    class: "texts",
    innerHTML: _ctx.content,
    key: 1
  }, null, 8, _hoisted_9))])])], 2)) : (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createVNode(_component_el_card, {
    class: "box-card"
  }, {
    header: _withCtx(() => [_createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, _toDisplayString(decodeURIComponent(_ctx.title)), 1), _createVNode(_component_el_button, {
      class: "button",
      text: ""
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.cardClickedIndex + 1), 1)]),
      _: 1
    })])]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentSource, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["text item", _ctx.cardClickedIndex == index ? 'cardItemsClicked' : '']),
        onClick: $event => _ctx.cardItemsClicked(index)
      }, _toDisplayString(item.title), 11, _hoisted_14);
    }), 128))]),
    _: 1
  })]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [!_ctx.content ? (_openBlock(), _createBlock(_component_el_empty, {
    key: 0,
    description: "description",
    default: "内容为空"
  })) : (_openBlock(), _createElementBlock("div", {
    style: {
      "font-family": "'ThinkOfSource'"
    },
    class: "px-3",
    innerHTML: _ctx.content,
    key: 1
  }, null, 8, _hoisted_17))])])])]))]);
}